import { AlgorithmBinding } from "./algorithm-binding";
import { Metric } from "./metric";
import { ThingDefinition } from "./thing-definition";

export interface WorkSessionDefinition {
    id: string;
    name: string;
    title: string;
    description: string;
    thingDefinition: ThingDefinition;
    startConditionMetric: Metric;
    startConditionPredicate: WorkSessionDefinitionPredicate;
    startConditionValue: string;
    stopConditionMetric: Metric;
    stopConditionPredicate: WorkSessionDefinitionPredicate;
    stopConditionValue: string;
    monitoredMetrics: Metric[];
    templateName: string;
    workSessionStartedNotificationTitle: string;
    workSessionStartedNotificationLongMessage: string;
    workSessionStartedNotificationShortMessage: string;
    workSessionStoppedNotificationTitle: string;
    workSessionStoppedNotificationLongMessage: string;
    workSessionStoppedNotificationShortMessage: string;
    group: string;
    originalId: string;
    originalThingDefinitionId: string;
    manualActivation: boolean;
    postComputationAlgorithms: WorkSessionAlgorithmBinding[];
    monitoringComputationPolicy: WorkSessionMonitoringComputationPolicy;
    topics: WorkSessionTopic[];
}

export enum WorkSessionDefinitionPredicate {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
    LESS = 'LESS',
    GREATER = 'GREATER',
    LESS_EQUAL = 'LESS_EQUAL',
    GREATER_EQUAL = 'GREATER_EQUAL',
    IS_EMPTY = 'IS_EMPTY',
    IS__NOT_EMPTY = 'IS__NOT_EMPTY'
}

export interface WorkSessionAlgorithmBinding extends AlgorithmBinding {
    resultField: string;
}

export enum WorkSessionMonitoringComputationPolicy {
    CONTINUOUS = 'CONTINUOUS',
    END = 'END'
}

export enum WorkSessionTopic {
    CONNECTION = 'CONNECTION',
    UPTIME = 'UPTIME',
    DOWNTIME = 'DOWNTIME',
    HEALTH = 'HEALTH',
    PRODUCTIVITY = 'PRODUCTIVITY',
    QUALITY = 'QUALITY',
    CONSUMPTION = 'CONSUMPTION',
    MAINTENANCE = 'MAINTENANCE',
    TEST = 'TEST'
}